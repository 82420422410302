.navbar {
  background-color: #fff;
  height: auto; 
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
    top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%; 
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.logo {
  flex: 1;
}

.logo img {
  width: 70px;
  margin-top: 8px;
}

.nav {
  flex: 2;
}

.nav-list {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  
}

.nav-list li {
  margin-left: 30px;
  font-weight: 1000;
  font-size: large;
}

.nav-list li a {
  color:  #007bff; 
}


.nav-list li a.active {
  color: #333; 
}


.toggle {
  display: none;
}

@media screen and (max-width: 990px) {
  body {
    overflow-x: hidden; 
  }
  
  .container {
    flex-direction: column; 
    align-items: flex-start; 
    padding: 10px;
    overflow-x: hidden;
  }

  .logo {
    margin-bottom: 10px;
    
  }

  .toggle {
    display: block;
    order: 1;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .nav-list {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    margin-top: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .nav-list.small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-list.small li {
    margin: 10px 0;
  }

  .toggle button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding-right: 30px;
    padding-top: 20px;
  }
}
