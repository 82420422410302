.card-t{
    max-width: 500px;
  min-width: 500px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-g{
  max-width: 500px;
  min-width: 500px;
  min-height: 120px;
  
}


@media screen and (max-width: 990px) {
  .card-t{
    max-width: 300px;
    min-width: 300px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-g{
    max-width: 200px;
    min-width: 200px;
    min-height: 120px;
    
  }
  
}