.hover-card:hover {
  background-color: lightblue; /* Change this to the desired hover background color */
}

.quote-card {
  max-width: 500px;
  min-width: 500px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-max{
  max-width: 200px;
  min-width: 200px;
  min-height: 50px;
  max-height: 50px;
 }
 .search-container input{
  width: 400px; 
  height: 40px; 
  padding: 0 15px; 
  text-align: center;
  border-radius: 20px;
  border: 1px solid #ccc; 
  background-color: #fff; 
  transition: border-color 0.2s ease-in-out; 
 }
@media screen and (max-width: 990px) {
  .quote-card {
    max-width: 300px;
    min-width: 300px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-max{
    max-width: 150px;
    min-width: 150px;
    min-height: 50px;
    max-height: 50px;
  }
}